#side_menu {
    position: fixed;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    z-index: 3;
    background-color: white;

    will-change: transform;
    transition: transform 0.4s ease;
}

#side_menu.open {
    transform: translateX(-100%);
}

#side_menu nav,
#side_menu ul {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5vh;
}

#side_menu li {
    list-style: none;
}

#side_menu li a {
    color: var(--black);
    font-size: 7.5vmin;
}