/* Home */

.home {
    background-color: var(--black);
    color: white;
}

.light-button,
.strong-button {
    color: white;
    border: 2px solid white;
    border-radius: 1px;
    width: 215px;
    height: 55px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.08s ease-out;
}

.light-button {
    background-color: inherit;
}

.strong-button,
.light-button:hover {
    background-color: white;
    color: var(--black);
}

.strong-button:hover {
    color: white;
}

/* Header */

.home header {
    display: flex;
    justify-content: space-around;
    position: relative;
}

.home__left_menu {
    display: flex;
    gap: calc(5vw);
}

.home__left_menu a {
    position: relative;
    color: white;
    letter-spacing: 1px;
    padding: 0 10px;
}


.home__logo_container {
    position: absolute;
    margin-top: 20px;
}

.home__logo_container-logo {
    height: 250px;
    filter: drop-shadow(0 4px 11px var(--blue));
    transition: filter 0.8s ease;
}

.home__logo_container-logo:hover {
    filter: drop-shadow(0 4px 30px var(--violet));
}

.home__right_menu {
    display: flex;
    gap: calc(5vw);
}

.home__right_menu a {
    color: white;
    letter-spacing: 1px;
    padding: 0 10px;
    height: 2.5em;
    width: 8em;
}

.home__nav-button {
    margin-top: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
}

.hamburger-react {
    position: fixed !important;
    right: 0;
    top: 0;
    margin: 10px 20px;
    z-index: 4;
}

.hamburger-react div {
    outline: solid 1px var(--black);
}

.hamburger-react[aria-expanded='true'] div {
    background-color: var(--black) !important;
    outline: none;
}

@media screen and (max-width: 1300px) {
    .home header {
        gap: 15vw;
    }

    .home__left_menu a,
    .home__right_menu a {
        font-size: 18px;
    }
}

@media screen and (max-width: 1000px) {

    .home__left_menu,
    .home__right_menu {
        flex-direction: column;
        gap: 0;
    }

    .home__left_menu a {
        margin-top: 4rem;
    }

    .home__right_menu a {
        border: none;
        height: auto;
    }

    .home__nav-button {
        margin-top: 4rem;
    }

    .home header {
        gap: 0;
    }

    .home__right_menu-contact-button {
        font-size: 1.2rem;
        width: 180px;
        height: 50px;
    }
}

@media screen and (max-width: 850px) {
    .home__logo_container {
        position: relative;
    }

    .home__left_menu,
    .home__right_menu {
        display: none;
    }
}

@media screen and (min-width: 851px) {
    .hamburger-react {
        display: none;
    }
}

/* ==== */

.home__main_content {
    margin: 0 3%;
}

.home__main_content-title {
    font-family: 'Lazer84';
    font-size: 5.5vw;
    letter-spacing: 4px;
    text-align: center;
    margin-top: 170px;
}

.home__main_content-subtitle {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    margin-top: 5vh;
}

.home__main_content-text {
    font-size: 22px;
    margin: 6vh 15% 5vh 15%;
    text-align: center;
}

.home__main_buttons {
    display: flex;
    justify-content: center;
    gap: 100px;
    padding: 60px 0 80px 0;
}

.home__main_buttons>a {
    font-weight: bold;
    border-radius: 50px;
    line-height: 1;
    user-select: none;
}

.home__main_buttons-services {
    background-image: linear-gradient(to right, var(--violet), var(--blue));
    text-shadow: 0;
    border: none;
    animation: violet-neon 1.5s ease-in-out infinite alternate;
    transition: transform 0.2s ease-in-out;
}

.home__main_buttons-services:hover {
    color: white;
    text-shadow: 0 0 10px #fff;
    transform: translateY(-3px);
}

@media (pointer: coarse) {
    .home__main_buttons-services {
        animation: none;
        box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 10px var(--violet),
            0 0 25px var(--violet), 0 0 30px var(--violet), 0 0 40px var(--violet), 0 0 65px var(--violet);
    }
}

@keyframes violet-neon {
    from {
        box-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px var(--violet),
            0 0 70px var(--violet), 0 0 80px var(--violet), 0 0 100px var(--violet), 0 0 150px var(--violet);
    }

    to {
        box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 10px var(--violet),
            0 0 25px var(--violet), 0 0 30px var(--violet), 0 0 40px var(--violet), 0 0 65px var(--violet);
    }
}

@media screen and (max-width: 1300px) {
    .home__main_content-subtitle {
        font-size: 20px;
    }

    .home__main_content-text {
        font-size: 16px;
    }

    .home__main_buttons {
        gap: 70px;
    }
}

@media screen and (max-width: 1000px) {
    .home__main_content-title {
        margin-top: 110px;
    }
}

@media screen and (max-width: 850px) {
    .home__main_content-title {
        margin-top: 45px;
        font-size: 2rem;
    }

    .home__main_content-text {
        margin: 6vh 5% 5vh 5%;
    }

    .home__main_buttons {
        flex-direction: column;
        align-items: center;
        gap: 2.5rem;
    }

    .home__main_buttons>a {
        width: 300px;
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 500px) {
    .home__logo_container-logo {
        height: 50vw;
    }

    .home__main_buttons>a {
        width: 90%;
    }
}

@media screen and (max-width: 350px) {
    .home__main_content-title {
        font-size: 25px;
    }
}

/* ==== */