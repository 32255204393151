:root {
    --slider-transition-time: 0s;
}

.slider {
    width: 100%;
}

.slider__item-wrapper {
    width: 100%;
    height: 100%;
}