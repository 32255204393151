/* About us */

.about_us {
    background-color: var(--light-grey);
    color: var(--black);
}

.about_us-title {
    font-size: 3vw;
    text-align: center;
    padding: 50px 0 100px 0;
    margin: 0 6vw;
}

.about_us__content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 6vw;
    height: 100%;
}

.about_us__content-tilt {
    padding: 0 !important;
    width: fit-content !important;
    height: fit-content;
    margin: 0 !important;
    box-shadow: none !important;
    border-radius: 50% !important;
    background-color: transparent !important;
}

.about_us__content-image {
    width: 500px;
    height: auto;
    border-radius: 50%;
}

.about_us__right_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5em;
    margin-bottom: 3em;
    max-width: 1000px;
}

.about_us__right_content p {
    font-size: 1.2rem;
}

.about_us__right_content-text {
    font-size: 1.2rem;
    margin-left: 3rem;
    font-style: italic;
    border-left: solid 4px grey;
    padding-left: 10px;
}

.about_us_right_content-services {
    position: relative;
    color: var(--violet);
    font-weight: 800;
    font-style: normal;
    display: inline-block;
}

.about_us_right_content-services::after {
    content: '';
    position: absolute;
    background-color: var(--violet);
    height: 3px;
    width: 0;
    left: 0.7rem;
    bottom: -2px;
    transition: width 0.2s ease;
}

.about_us_right_content-services:hover::after {
    width: 90%;
}

.about_us__content__social_medias {
    display: flex;
    gap: 2em;
    justify-content: center;
    flex-wrap: wrap;
}

.about_us__content__social_medias a {
    height: 5rem;
    width: 5rem;
    display: flex;
    justify-content: center;
}

.about_us__content__social_medias a:nth-child(1) {
    background-color: #3b5998;
}

.about_us__content__social_medias a:nth-child(1) img {
    padding-bottom: 1rem;
    width: 90%;
}

.about_us__content__social_medias a:nth-child(2) {
    background: radial-gradient(circle at 0% 50%, #FFC837, #F77737, #F56040, #FD1D1D, #C13584, #833AB4, #5851DB, #405DE6);
}

.about_us__content__social_medias a:nth-child(3) {
    background-color: #00acee;
}

.about_us__content__social_medias a:nth-child(3) img {
    width: 80%;
}

.about_us__content__social_medias a:nth-child(4) {
    background-color: #7289DA;
}

.about_us__content__social_medias a:nth-child(4) img {
    width: 90%;
}

.social_media_badge {
    display: flex;
    align-items: center;
    background-color: white;
    color: white;
    border-radius: 3em;
}

.social_media_badge img {
    padding: 0.5em;
    width: 80px;
}

.social_media_badge span {
    font-family: 'Poppins';
    font-size: 1.8em;
    padding: 0 1em;
}

@media screen and (max-width: 1700px) {
    .about_us-title {
        padding: 40px 0 70px 0;
    }
}

@media screen and (max-width: 1600px) {
    .about_us-title {
        font-size: 2.5rem;
    }

    .about_us__content-image {
        width: 400px;
    }
}

@media screen and (max-width: 1400px) {
    .about_us__right_content-text {
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 1300px) {
    .about_us__right_content-text {
        font-size: 1.1rem;
    }

    .social_media_badge span {
        font-size: 1.5em;
    }
}

@media screen and (max-width: 1200px) {
    .about_us__content-image {
        display: none;
    }

    .about_us__right_content p {
        padding-top: 2em;
    }

    .social_media_badge img {
        width: 80px;
    }

    .social_media_badge span {
        font-size: 1.8em;
    }
}

@media screen and (max-width: 850px) {
    .about_us-title {
        font-size: 2rem;
    }

    .about_us__content-image {
        width: 350px;
    }

    .about_us__right_content-text {
        font-size: 1rem;
        margin-left: 0;
    }

    .social_media_badge span {
        font-size: 1.5em;
    }
}

/* make about_us__content__social_medias a grid with 4 item aligned, but 2 lines of 2 items when mobile screen */
@media screen and (max-width: 500px) {
    .about_us__content__social_medias {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 2rem;
    }
}

@media screen and (max-width: 350px) {
    .about_us-title {
        font-size: 1.5rem;
    }
}

/* ==== */