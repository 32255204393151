.news-section {
    background-color: var(--black);
    padding: 0 4rem 10rem 4rem;
    color: white;
    min-height: 0 !important;
}

@media screen and (max-width: 1025px) {
    .news-section {
        padding: 0 1rem 10rem 1rem;
    }
}