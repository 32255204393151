/* Contact */

.contact {
    padding: 2em 15vw;
    background-color: var(--light-grey);
    color: var(--black);
}

.contact-title {
    font-size: 3rem;
    margin-bottom: 1em;
}

.contact-text {
    font-size: 1rem;
    margin-bottom: 3em;
    width: 50%;
    line-height: 1.6;
}

.contact__card {
    display: flex;
    width: 100%;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    overflow: hidden;
}

.contact__card__form,
.contact__card__infos {
    padding: 2em;
}

.contact__card__form {
    background-color: white;
    width: 60%;
}

.contact__card__inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-bottom: 2em;
}

.contact__card-input {
    position: relative;
}

.contact__card-input input,
.contact__card-input textarea {
    width: 100%;
    border: none;
    outline: none;
    padding-top: 20px;
}

.contact__card-input input {
    font-size: 1.4rem;
    padding-bottom: 10px;
    font-family: 'Poppins';
}

.contact__card-input textarea {
    font-size: 1.2rem;
    height: 5em;
}

.contact__card-input label {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    border-bottom: 1px solid grey;
    font-size: 0.8em;
}

.contact__card-input input:valid+label,
.contact__card-input textarea:valid+label {
    border-bottom: 2px solid green;
}

.contact__card-input input:focus+label,
.contact__card-input textarea:focus+label {
    border-bottom: 2px solid var(--violet);
}

.contact__card-submit {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2em 0 1em 0;
}

.contact__card-submit input {
    color: white;
    background-image: linear-gradient(to right, var(--violet), var(--blue));
    font-size: 1.3em;
    border: none;
    width: 150px;
    height: 50px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: box-shadow 0.3s cubic-bezier(.25, .8, .25, 1);
}

.contact__card-submit input+span {
    position: absolute;
    color: white;
    letter-spacing: 2px;
    pointer-events: none;
}

.contact__card-submit input:active,
.contact__card-submit input.success,
.contact__card-submit input.waiting {
    box-shadow: none;
}

.contact__card-submit input.waiting,
.contact__card-submit input.success {
    cursor: default;
}

.contact__card-submit input.waiting+span {
    display: none;
}

.contact__card-submit input.success {
    background-image: none;
    background-color: green;
}

.contact__card-submit input.failed {
    background-image: none;
    background-color: red;
}

.contact__card__infos {
    color: white;
    background-color: var(--violet);
    width: 40%;
}

.contact__card__form h3,
.contact__card__infos h3 {
    font-size: 1.5rem;
    margin-bottom: 1.5em;
}

.contact__card__infos h3 {
    text-align: center;
}

.contact__card__infos__contents {
    display: flex;
    height: 80%;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 0.5em;
}

.contact__card__infos__info_group {
    display: flex;
    gap: 15%;
}

.contact__card__infos__info_group.--hours {
    margin-top: 1em;
}

.contact__card__infos__info_group-title {
    text-align: right;
    width: 50%;
    color: #ccc;
}

.contact__card__infos__info_group-content {
    font-family: 'Poppins';
    font-size: 0.9em;
    width: 50%;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.contact__card__infos__info_group-content a {
    color: inherit;
}

.spaced-line {
    margin-bottom: 0.5em;
}

@media screen and (max-width: 1300px) {
    .contact {
        padding: 2em 10vw;
    }
}

@media screen and (max-width: 1100px) {
    .contact {
        padding: 2em 5vw;
    }

    .contact-title {
        font-size: 2.5em;
    }

    .contact-text {
        width: 75%;
    }

    .contact__card__form h3,
    .contact__card__infos h3 {
        font-size: 1.3rem;
        margin-bottom: 1.3em;
    }

    .contact__card__inputs {
        grid-template-columns: 1fr;
    }

    .contact__card-input input {
        font-size: 1.2rem;
        padding-bottom: 10px;
    }

    .contact__card__infos__info_group {
        flex-direction: column;
    }

    .contact__card__infos__info_group-title,
    .contact__card__infos__info_group-content {
        width: 100%;
        text-align: center;
    }
}

@media screen and (max-width: 800px) {
    .contact-text {
        width: 100%;
    }

    .contact__card {
        flex-direction: column-reverse;
    }

    .contact__card__form,
    .contact__card__infos {
        width: 100%;
    }

    .contact__card__infos__contents {
        gap: 1em;
    }

    .contact__card__infos__info_group {
        flex-direction: row;
    }

    .contact__card__infos__info_group-title {
        text-align: right;
    }

    .contact__card__infos__info_group-content {
        text-align: left;
    }
}

@media screen and (max-width: 350px) {
    .contact-title {
        font-size: 2rem;
    }
}

/* ==== */