/* Services */

.services {
  background-color: var(--black);
  color: white;
}

.services__cards {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 3rem;
  padding-bottom: 10vh;
  gap: 3em;
  flex-wrap: wrap;
}

.services__tarifs {
  p {
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }

  a {
    margin: 0 auto;
  }
}

@media screen and (max-width: 1000px) {
  .services-title {
    font-size: 2rem;
  }
}

/* First wrapping action of cards */
@media screen and (max-width: 863px) {
  .services__cards {
    margin-top: 10vh;
  }
}

/* ==== */
