*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

@font-face {
  font-family: 'LemonMilk';
  src: url('./assets/fonts/Lemon\ milk/LEMONMILK-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Lazer84';
  src: url('./assets/fonts/Lazer84.ttf') format('truetype');
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'LemonMilk', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: 'LemonMilk';
  cursor: pointer;
}

a[href] {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-weight: normal;
}

textarea {
  resize: none;
}