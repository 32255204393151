.bottom_page_button {
    position: fixed;
    bottom: 0;
    transform-origin: center;
    background-color: white;
    border-radius: 50%;
    border: 1px solid var(--black);
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    z-index: 1;
    transition: all 0.5s ease;
}

.bottom_page_button--to_bottom {
    right: 50%;
    transform: translateX(50%);
}

.bottom_page_button--to_top {
    right: 0;
    margin-right: 15px;
    transform: rotate(180deg);
}

@media screen and (max-width: 850px) {
    .bottom_page_button:not(.bottom_page_button--to_top) {
        right: 0;
        transform: none;
        margin-right: 15px;
    }
}

@media screen and (max-height: 600px) {
    .bottom_page_button:not(.bottom_page_button--to_top) {
        right: 0;
        transform: none;
        margin-right: 15px;
    }
}