/* Slider */

.slider {
    display: flex;
    height: 20rem;
    max-width: 80rem;
    margin: 0 auto;
}

.slider__container {
    position: relative;
    width: 100%;
}

.slider__item-wrapper {
    position: absolute;
    top: 0;
    left: 0;
}

.slider__item-wrapper:not(.active):not(.closing) { /* closed */
    z-index: -1;
    visibility: hidden;
}

.slider__item-wrapper.active {
    opacity: 0;
    animation: fadeIn var(--slider-transition-time) forwards;
    animation-delay: var(--slider-transition-time);
    z-index: 1;
}

.slider__item-wrapper.closing {
    z-index: 1;
    animation: fadeOut var(--slider-transition-time) forwards;
}

.slider__previous,
.slider__next {
    background: none;
    border: none;
    fill: white;
    border-radius: 8px;
    padding: 0 1rem;
    height: 100%;
}

.slider__previous:hover,
.slider__next:hover {
    background-color: rgba(255, 255, 255, 0.1);
    outline: 2px solid rgba(255, 255, 255, 0.1);
}

.slider__previous:active,
.slider__next:active {
    outline-width: 0px;
}

.slider__previous > svg {
    transform: rotate(90deg);
}

.slider__next > svg {
    transform: rotate(-90deg);
}

@media screen and (max-width: 1025px) {
    .slider__previous,
    .slider__next {
        padding: 0;
    }
}

@media screen and (max-width: 650px) {
    .slider {
        height: 34rem;
    }

    .slider__previous,
    .slider__next {
        height: clamp(10rem, 50vh,20rem);
        position: sticky;
        top: 25%;
    }
}

@media screen and (max-width: 450px) {
    .slider__btn-container svg {
        margin: 0 -10px;
    }
}

/* changer pour transition */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

/* Slider content */

.news {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 100%;
    padding: 0 1rem;
    height: 100%;
    gap: 3rem;
}

.news__image-wrapper {
    border: 4px solid white;
    transition: transform var(--slider-transition-time);
}

.news__image-wrapper.no-image img {
    object-fit: contain;
}

.slider__item-wrapper:not(.active) .news__image-wrapper {
    transform: translateY(-1rem);
}

.slider__item-wrapper.active .news__image-wrapper {
    transform: translateY(0);
    transition-delay: var(--slider-transition-time);
}

.news__image-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.news__content-container {
    padding-right: 0.5rem;
    overflow-y: auto;
}

.news__content-container::-webkit-scrollbar {
    width: 8px;
}

.news__content-container::-webkit-scrollbar-track {
    background-color: transparent;
}

.news__content-container::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
}

.news__content-container::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.news__content-title {
    font-size: clamp(1.2rem, 3.5vw, 2rem);
    letter-spacing: 2px;
    margin-bottom: 1.5rem;
    text-wrap: balance;
}

.news__content-text {
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    line-height: 1.5;
}

@media screen and (max-width: 1025px) {
    .news {
        gap: 1.5rem;
    }
}

@media screen and (max-width: 650px) {
    .news {
        grid-template-columns: 1fr;
        grid-template-rows: 20rem auto;
        padding: 0 1rem;
        height: 100%;
    }
}