:root {
  --black: #191919;
  --light-grey: #f0f0f0;
  --violet: #7a13cc;
  --blue: #1665bc;
}

.App *::selection {
  background-color: var(--violet);
  color: white;
}

.section {
  margin: -3px 0;
  padding-top: 5rem;
  padding-bottom: 10rem;
}

.section.--first {
  padding-top: 0;
  padding-bottom: 0;
}

@media screen and (max-width: 1025px) {
  .section {
      padding-top: 2rem;
      padding-bottom: 5rem;
    }
}

.material-shadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: box-shadow 0.3s cubic-bezier(.25, .8, .25, 1);
  user-select: none;
}

a.material-shadow:hover,
button.material-shadow:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

a.material-shadow:active,
button.material-shadow:active {
  box-shadow: none;
}

.section-title {
  font-size: clamp(2rem, 4vw, 5rem);
    text-align: center;
    padding: 4rem 0 6rem 0;
}