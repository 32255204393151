/* Footer */

.footer {
  border-top: solid 15px var(--light-grey);
  font-family: "Poppins";
}

.footer__links {
  background-color: var(--black);
  color: white;
  display: flex;
  align-items: center;
  padding: 0.5em;
}

.footer__links__contacts,
.footer__links__social_medias {
  display: flex;
  flex-direction: column;
  gap: 0.8em;
}

.footer__links__contacts__contact,
.footer__links__social_medias__media {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8em;
}

.footer__links__social_medias__media {
  justify-content: right;
}

.footer__links__contacts__contact-icon {
  filter: invert(100%);
  width: 30px;
}

.footer__links__social_medias__media-logo {
  width: 30px;
}

.footer__links__contacts__contact-content,
.footer__links__social_medias__media-link {
  font-size: 0.8em;
  color: white;
}

.footer__links__navigation {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  white-space: nowrap;
}

.footer__links__navigation-item {
  color: white;
  font-size: 1em;
}

.footer__legal_infos {
  font-size: 0.8rem;
  text-align: center;
  background-color: var(--light-grey);
  color: black;
  padding: 1.5em 0.5rem;
  position: relative;
  z-index: 2;
}

@media screen and (max-width: 850px) {
  .footer__legal_infos {
    font-size: 0.6rem;
  }

  .footer__links__navigation-item {
    font-size: 0.8em;
  }
}

@media screen and (max-width: 700px) {
  .footer__links__navigation {
    flex-direction: column;
    gap: 0.5em;
  }
}

@media screen and (max-width: 500px) {
  .footer__links {
    flex-direction: column;
    gap: 1em;
  }

  .footer__links__contacts {
    flex-direction: row;
    justify-content: space-around;
    gap: 1em;
  }

  .footer__links__navigation {
    flex-direction: row;
  }

  .footer__links__social_medias {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    column-gap: 10vw;
    place-items: center;
  }
}

@media screen and (max-width: 400px) {
  .footer__links__contacts {
    flex-direction: column;
    gap: 0.3em;
  }

  .footer__links__contacts__contact {
    justify-content: center;
  }
}

/* ==== */
