.services__cards .card {
    position: relative;
    background-color: transparent;
    width: 20em;
    height: 28em;
    color: white;
    cursor: pointer;
    perspective: 1000px;
    transition: transform 0.3s cubic-bezier(.25, .8, .25, 1);
}

.services__cards .card:hover {
    transform: scale(1.1);
}

.services__cards .card::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(45deg, #1665bc, #374ac1);
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.6);
    transition: transform 0.8s cubic-bezier(.25, .8, .25, 1);
}

.services__cards .card::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(45deg, #1665bc, #374ac1);
    filter: blur(30px);
    transition: transform 0.8s cubic-bezier(.25, .8, .25, 1);
}

.services__cards .card:nth-child(2)::before,
.services__cards .card:nth-child(2)::after {
    background-image: linear-gradient(45deg, #374ac1, #582fc6);
}

.services__cards .card:nth-child(3)::before,
.services__cards .card:nth-child(3)::after {
    background-image: linear-gradient(45deg, #582fc6, #7a13cc);
}

.services__cards .card:nth-child(4)::before,
.services__cards .card:nth-child(4)::after {
    background-image: linear-gradient(45deg, #7a13cc, #9b00d4);
}

.services__cards .card span {
    position: absolute;
    top: 6px;
    right: 6px;
    bottom: 6px;
    left: 6px;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 2;
    transition: transform 0.8s cubic-bezier(.25, .8, .25, 1);
}

.services__cards .card span::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    pointer-events: none;
}

.services__cards .card__inner {
    position: relative;
    z-index: 2;
    height: 100%;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.8s cubic-bezier(.25, .8, .25, 1);
}

/* https://bugzilla.mozilla.org/show_bug.cgi?id=1201471 */
@-moz-document url-prefix() {
    .services__cards .card__inner {
        backface-visibility: hidden;
    }
}

.services__cards .card.flipped::before,
.services__cards .card.flipped::after,
.services__cards .card.flipped span,
.services__cards .card.flipped .card__inner {
    transform: rotateY(180deg);
}

.card__inner__front {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.neon_icon {
    width: 100px;
    padding-bottom: 3em;
    filter: drop-shadow(2px 4px #fff);
    opacity: 0.6;
}

.card__inner__front,
.card__inner__back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    /* Safari */
    -webkit-backface-visibility: hidden;
}

.card__inner__back {
    transform: rotateY(180deg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2em;
    text-align: center;
}

.card__inner__back .card-description {
    font-size: 1.2em;
    margin-bottom: 3em;
}

.card__inner__back .card-from,
.card__inner__back .card-disclaimer {
    font-family: 'Poppins';
    font-size: 1em;
    color: #ccc;
}

.card__inner__back .card-price {
    font-size: 3.5em;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
}

@media screen and (max-width: 1300px) {
    .services__cards .card {
        width: 18em;
        height: 25em;
    }
}

@media screen and (max-width: 1100px) {
    .services__cards .card {
        width: 14em;
        height: 20em;
    }
}